<template>
  <mnb-input
    v-model="file"
    v-bind="options"
    @change="uploadFile" />
</template>

<script>
import MnbInput from './Input'

import inputMixin from './input-mixin.js'
import helperMixin from '../../common/helper-mixin'

export default {
  mixins: [inputMixin, helperMixin],

  props: {
    full: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classes: {
      type: Object,
      default: null
    }
  },

  components: {
    MnbInput
  },

  data () {
    return {
      file: null
    }
  },

  computed: {
    options () {
      return {
        type: 'file',
        full: this.full,
        label: this.label,
        classes: this.classes,
        disabled: this.disabled
      }
    }
  },

  watch: {
    value (value) {
      if (!value) this.file = null
    }
  },

  methods: {
    uploadFile ($e) {
      const file = $e.target.files[0]
      if (this.isImage(file)) {
        this.$emit('input', file)
      }
    }
  }
}
</script>