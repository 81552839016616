<template>
  <div class="dropdown-button">
    <button :disabled="disabled" class="btn btn-block" @click="open = !open">{{ text }}</button>
    <ul v-show="open" class="dropdown-options">
      <slot />
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },

    disabled: {
      type: Boolean,
      default: null
    }
  },

  data () {
    return {
      open: false
    }
  }
}
</script>
