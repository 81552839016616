<template>
  <form-wrapper
    :label="label"
    :required="isRequired"
    :errors="errors"
    :class="{ focused, filled: focused || data, disabled: disabled }">
    <div
      class="select select-search"
      :class="{ error: hasError, disabled }"
      v-on:click.prevent="doOpen">
      <span>{{ selected }}</span>
      <ul class="options options-with-group" v-if="optionGroup" v-on:focus="focused=true" v-on:blur="focused=false" :class="{open}">
        <li class="search" v-on:click.stop="">
          <input v-model="q" ref="q" placeholder="Type here to filter" />
        </li>
        <li class="empty" v-on:click.prevent.stop="$emit('input', null); open=false">
          <a href="">&nbsp;</a>
        </li>
        <li v-for="(groupOptions, group) in options" :key="group">
          <span v-text="group"></span>
          <ul>
            <li :class="{disabled: option.disabled}" v-for="option in groupOptions" :key="option.id" v-on:click.prevent.stop="!option.disabled && $emit('input', option.id); open=false" v-if="showOpt(option)">
              <a href="">{{ option.text }}</a>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="options options-plain" v-else :class="{open}">
        <li class="search" v-on:click.stop="">
          <input v-model="q" ref="q" placeholder="Type here to filter" />
        </li>
        <li class="empty" v-on:click.prevent.stop="$emit('input', null); open=false">
          <a href="">&nbsp;</a>
        </li>
        <li :class="{disabled: option.disabled}" v-for="option in parsedOptions" :key="option.id" v-on:click.prevent.stop="!option.disabled && $emit('input', option.id); open=false" v-if="showOpt(option)">
          <a href="">{{ option.text }}</a>
        </li>
      </ul>
    </div>
  </form-wrapper>
</template>

<script>
import inputMixin from './input-mixin'

export default {
  mixins: [inputMixin],

  props: {
    options: {
      type: [Object, Array],
      default: () => []
    },

    optionGroup: {
      type: Boolean,
      default: false
    }
  },
  
  data: ()=>({
    open: false,
    q: "",
  }),

  computed: {
    selected () {
      const options = this.optionGroup
        ? Object.values(this.options).reduce((acc, arr) => acc.concat(arr), [])
        : this.parsedOptions
      
      return options.find(opt => opt.id === this.data)?.text


    },

    parsedOptions () {
      if (Array.isArray(this.options)) return this.options
      return Object.keys(this.options).map(key => ({
        id: key,
        text: this.options[key]
      }))
    },
  },
  
  methods: {
    doOpen() {
      this.open=!this.open;
      if (this.open) {
        this.$nextTick(()=>this.$refs.q && this.$refs.q.focus());
      }
    },
    showOpt(option) {
      if (!this.q)
        return true;
      return (option.text || "").toLowerCase().indexOf(this.q.toLowerCase())!=-1;
    },
  },
}
</script>
<style lang="scss" scoped>
  ul.options {
    display: none;
    &.open {
      display: block;
    }
    position: absolute;
    z-index: 20;
    top: 100%;
    left: 0%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    max-height: 300px;
    border: 1px solid #bec9d4;
    background: white;
    >li.search {
      position: sticky;
      top: 0;
      z-index: 1;
      input {
        line-height: 22px;
        height: 24px;
        border-radius: 0;
        border-top: 0px none;
        border-left: 0px none;
        border-right: 0px none;
      }
    }
    >li.empty {
      &:hover {
        background: #41566c;
        color: white;
      }
      a {
        line-height: 20px;
        padding: 2px 4px;
        display: block;
        color: inherit;
        text-decoration: none;
      }
    }
    &.options-plain>li {
      &:not(.disabled):hover {
        background: #41566c;
        color: white;
      }
      a {
        line-height: 20px;
        padding: 2px 4px;
        display: block;
        color: inherit;
        text-decoration: none;
        font-weight: normal;
      }
    }
    &.options-with-group>li {
      >span {
        display: block;
        padding: 2px 4px;
        font-weight: bold;
        line-height: 20px;
      }
      >ul>li {
        &:not(.disabled):hover {
          background: #41566c;
          color: white;
        }
        a {
          line-height: 20px;
          padding: 2px 4px 2px 16px;
          display: block;
          color: inherit;
          text-decoration: none;
          font-weight: normal;
        }
      }
    }
    li.disabled {
      opacity: 0.5;
    }
  }
</style>
