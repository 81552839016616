<template>
  <div class="cof-field" :class="{ padingless: noStyle || noMargin, error: hasError }">
    <label v-if="!noStyle && label">{{ label }}: <span v-if="isRequired" class="required">*</span></label>
    <slot />
    <span v-if="!noStyle && validator" class="errors">
      <span v-for="error in errors" :key="error" class="help-block text-danger">{{ error }}</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    },

    validator: {
      type: Object,
      default: null
    },

    noMargin: {
      type: Boolean,
      default: false
    },

    noStyle: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      messages: {
        required: 'register.form_error_required',
        email: 'register.form_error_email',
        sameAs: 'register.form_error_sameAs',
        integer: 'register.form_error_integer',
        numeric: 'register.form_error_numeric',
        between: 'register.form_error_between',
        maxLength: 'register.form_error_maxLength',
        alpha: 'register.form_error_alpha',
        alphaNum: 'register.form_error_alphaNum',
        ipAddress: 'register.form_error_ipAddress',
        url: 'register.form_error_url',
        minLength: 'register.form_error_minLength',
        minValue: 'register.form_error_minValue',
        swift: 'register.form_error_swift',
      }
    }
  },

  computed: {
    hasError () {
      return this.errors.length > 0
    },

    isRequired () {
      return this.validator && this.validator.hasOwnProperty('required')
    },

    errors () {
      if (this.validator && this.validator.$dirty && !this.validator.$pending) {
        return Object.keys(this.validator).filter((prop) => !prop.startsWith('$') && !this.validator[prop]).map((prop) => {
          if (this.validator.$params[prop].message) {
            return this.validator.$params[prop].message
          }
          return this.$t(this.messages[prop])
        })
      }

      return []
    }
  }
}
</script>

<style lang="scss" scoped>
// .form-group {
//   display: inline-block;
//   vertical-align: middle;
//   width: 155px;
//   margin: 0 20px;
//   &.padingless {
//     padding: 0;
//     margin: 0;
//   }
//   label {
//     display: block;
//     font-size: 14px;
//     font-weight: 300;
//     color: #000;
//     margin-bottom: 0;
//     font-family: 'Roboto', sans-serif;
//   }
//   .errors {
//     display: block;
//     min-height: 15px;
//     font-size: 11px;
//     margin-top: 5px;
//     span.text-danger {
//       color: #d23131;
//     }
//   }
//   .required {
//     color: #d23131;
//   }
//   .error {
//     border: 1px solid #d23131;
//     border-radius: 5px;
//   }
// }
</style>
