<template>
<div class="validator" :class="{error: (submitted || value) && !valid, focused: focused, valid: submitted && valid}" v-on:focus.capture="focused=true" v-on:blur.capture="focused=false">
  <slot />
  <span class="error" v-if="(submitted || value) && !valid" v-html="validationError"></span>
</div>
</template>

<script>
export default {
  props: ["value", "reqs"],
  data() { return {
    submitted: false,
    focused: false,
  }},
  computed: {
    validationError() {
      var reqs=(this.reqs || "").split(" ").map((e)=>formValidation[e]).filter((e)=>e);
      for(var i in reqs) {
        if (!reqs[i](this.value))
          return reqs[i].message;
      }
      return "";
    },
    valid() {
      var reqs=(this.reqs || "").split(" ").map((e)=>formValidation[e]).filter((e)=>e);
      var valid=true;
      for(var i in reqs) {
        if (!reqs[i](this.value))
          valid=false;
      }
      return valid;
    },
  },
}
</script>
