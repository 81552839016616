import FormWrapper from './FormWrapper'

export default {
  props: ["label", "value", "required", "disabled", "validator"],

  components: {
    FormWrapper
  },

  data: () => ({
    data: null,
    focused: false,

    messages: {
      required: 'register.form_error_required',
      email: 'register.form_error_email',
      sameAs: 'register.form_error_sameAs',
      integer: 'register.form_error_integer',
      numeric: 'register.form_error_numeric',
      decimal: 'register.form_error_decimal',
      between: 'register.form_error_between',
      alpha: 'register.form_error_alpha',
      alphaNum: 'register.form_error_alphaNum',
      alphaNumNoSpace: 'register.form_error_alphaNumNoSpace',
      alphaOnly: 'register.form_error_alphaOnly',
      alphaVersionNoSpace: 'register.form_error_alphaVersionNoSpace',
      addressValidation: 'register.form_error_addressValidation',
      dashNumbers: 'register.form_error_dashNumbers',
      ipAddress: 'register.form_error_ipAddress',
      url: 'register.form_error_url',
      minLength: 'register.form_error_minLength',
      maxLength: 'register.form_error_maxLength',
      minValue: 'register.form_error_minValue',
      limit: 'register.form_error_limit',
      routingNumber: 'register.form_error_routingNumber',
      monbiAccountNumber: 'register.form_error_monbiAccountNumber',
      swift: 'register.form_error_swift',
      minLimit: 'register.form_error_minLimit',
      webSiteUrl: 'register.form_error_webSiteUrl',
    }
  }),

  computed: {
    hasError () {
      return this.errors.length
    },

    isRequired () {
      return this.required || this.validator?.hasOwnProperty('required')
    },

    errors () {
      if (this.validator && this.validator.$dirty && !this.validator.$pending) {
        return Object.keys(this.validator).filter((prop) => !prop.startsWith('$') && !this.validator[prop]).map((prop) => {
          if (this.validator.$params[prop]?.message) {
            return this.validator.$params[prop].message
          }
          return this.$t(this.messages[prop])
        })
      }

      return []
    }
  },

  watch: {
    value: {
      handler() {
        this.data = this.value;
      },
      immediate: true
    },
  }
}
