<template>
  <label class="mn-switch">
    <input v-show="false" type="checkbox" v-model="val" :validator="validator" :disabled="disabled">
    <span class="mn-slider" />
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    validator: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      val: this.value
    }
  },

  watch: {
    val (val) {
      this.$emit('input', val)
    },

    value (value) {
      this.val = value
    }
  }
}
</script>

<style lang="scss" scoped>
  .mn-switch {
    display: block;
    position: relative;
    padding: 5px 0;
    width: 35px;
    .mn-slider {
      display: block;
      width: 100%;
      height: 8px;
      border-radius: 4px;
      background-color: #d9dce1;
      cursor: pointer;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
        border-radius: 9px;
        background-color: #777777;
        transition: all 0.3s ease;
        cursor: pointer;
      }
    }
    input:checked + .mn-slider::before {
      left: calc(100% - 18px);
      background-color: #13ce67;
      
    }
    input:disabled + .mn-slider {
      cursor: auto;
      opacity: .75;
      &::before {
        cursor: auto;
        opacity: .75;
      }
    }
  }
</style>