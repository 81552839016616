<template>
  <div class="upload-input">
    <form-field-text :value="value ? value.name : null" :label="label" :validator="validator" :class="classes" :required="required" disabled />
    <button :disabled="disabled" :class="btnClasses" @click.prevent="$refs.file.click()">{{ $t('register.choose_file') }}</button>
    <input ref="file" v-show="false" type="file" @change="uploadFile($event)">
  </div>
</template>

<script>
export default {
  name: 'FormFieldUpload',

  props: {
    value: {
      type: [Object, File, Array],
      default: null
    },

    validator: {
      type: Object,
      default: null
    },

    label: {
      type: String,
      required: true
    },

    classes: {
      type: Object,
      default: null
    },

    btnClasses: {
      type: String,
      default: null
    },

    required: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    value: {
      default: null
    }
  },

  data () {
    return {
      file: this.value
    }
  },

  computed: {
    fileName () {
      return this.file?.name || null
    }
  },

  watch: {
    file (value) {
      this.$emit('input', value)
    }
  },

  methods: {
    uploadFile (event) {
      this.file = event.target.files[0]
      this.$refs.file.value = null
    }
  }
}
</script>

<style lang="scss" scoped>
  // .upload-input {
  //   position: relative;
  //   button {
  //     position: absolute;
  //     top: 6px;
  //     right: 6px;
  //     z-index: 2;
  //     width: auto;
  //     padding: 0 30px;
  //     height: 36px;
  //     &:disabled {
  //       opacity: .5;
  //       cursor: auto;
  //     }
  //   }
  // }
  .error {
    border: 1px solid red; 
  }
</style>
