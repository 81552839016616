export default {
  methods: {
    mixin_autoResize_resize(event) {
      if(event.target) {
        event.target.style.height = "auto";
        if(event.target.scrollHeight < 30) {
          event.target.style.height = '31px'
        } else {
          event.target.style.height = `${event.target.scrollHeight}px`;
        }
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      if(this.$el.getElementsByTagName('textarea')[0]){
        if(this.$el.getElementsByTagName('textarea')[0].scrollHeight < 30) {
          this.$el.getElementsByTagName('textarea')[0].style.height = '31px'
        } else {
          this.$el.getElementsByTagName('textarea')[0].style.height = `${this.$el.getElementsByTagName('textarea')[0].scrollHeight}px`;
        }
      }
    });
  }
};