<template>
<ul class="multiselect">
  <li v-for="option in options">
    <label>
      <input :disabled="readonly" type="checkbox" v-model="val" :value="option.value" v-on:change="updateValue" />
      {{ option.label }}
    </label>
  </li>
</ul>
</template>


<script>
export default {
  props: ["value", "options", "readonly"],
  data() { return {
    val: [],
  }},
  mounted() {
  },
  methods: {
    updateValue() {
      this.$emit("input", this.val);
    },
  },
  watch: {
    value() {
      this.val=this.value || [];
    },
  },
}
</script>
