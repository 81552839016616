<template>
  <div class="date">
    <span v-if="label && !day && !month && !year" class="label">{{ label }}</span>
    <form-field-select v-model="day" label="DD" :validator="$v.day" :required="required" :options="dayOptions" :disabled="disabled" class="date-item sm" />
    <form-field-select v-model="month" label="MM" :validator="$v.month" :required="required" :options="monthOptions" :disabled="disabled" class="date-item lg" />
    <form-field-select v-model="year" :label="$t('register.year_format')" :validator="$v.year" :required="required" :options="yearOptions" :disabled="disabled" class="date-item" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'FormFieldDate',
  props: {
    value:    { type: String,  default: null },
    label:    { type: String,  default: null },
    dirty:    { type: Boolean, default: null },
    disabled: { type: Boolean, default: null },
    required: { type: Boolean, default: false }
  },
  data: ()=>({
    day: null,
    month: null,
    year: null,
    date: null,
    
    monthOptions: []
  }),
  computed: {
    dayOptions () {
      return this.createOptions(31)
    },

    yearOptions () {
      if (this.label=='Business Incorporation Date') {
        var opts=[];
        var yearNow=new Date().getFullYear();
        for(var i=1990;i<=yearNow;i++)
          opts.push({id: i+'', text: i+''});
        return opts;
      } else
      return window.yearsOptions
    }
  },

  watch: {
    dirty () {
      this.$v.$touch()
    },

    value: {
      handler(value) {
        this.day = value ? value.substring(8,10) : null;
        this.month = value ? value.substring(5,7) : null;
        this.year = value ? value.substring(0,4) : null;
      },
      immediate: true
    },

    date: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true
    },

    day () {
      this.setDate()
    },

    month () {
      this.setDate()
    },

    year () {
      this.setDate()
    },

    '$i18n.locale' () {
      this.monthOptions = this.createOptions(12, true)
    }
  },

  created () {
    this.monthOptions = this.createOptions(12, true)
  },

  methods: {
    createOptions (number, m) {
      const options = [];
      for (let i = 1; i <= number; i++) {
        options.push({
          id: (i<10 ? "0" : "") + i,
          text: m ? this.$t(`months.${i}`) : i
        });
      }

      return options
    },

    setDate () {
      this.date = (this.day && this.month && this.year) ? `${this.year}-${this.month}-${this.day} 00:00:00` : null
    }
  },

  validations () {
    let rules = {}

    if (this.required) {
      rules = {
        day: { required },
        month: { required },
        year: { required }
      }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
.date {
  .label {
    pointer-events: none;
    overflow: hidden;
    position: absolute;
    height: 26px;
    line-height: 26px;
    transition: 0.3s;
    z-index: 1;
    font-size: 14px;
    color: #41566c;
    padding: 0 12px;
    top: -21px;
    left: 0;
    z-index: 5;
  }
}
</style>
