<template>
<div class="multi-dropdown" :class="{disabled}">
  <span class="value" v-on:click="open=!open">{{ shownValue }}</span>
  <ul ref="ddlist" v-if="open">
    <li v-for="option of options">
      <label v-if="isMulti">
        <input type="checkbox" v-model="componentValue" :value="option.value" v-on:click="doSelect" />
        <span></span>
        <span>{{ option.label }}</span>
      </label>
      <label v-else>
        <input type="radio" v-model="componentValue" :value="option.value" v-on:click="doSelect" />
        <span></span>
        <span>{{ option.label }}</span>
      </label>
    </li>
  </ul>
</div>
</template>

<script>
export default {
  props: ["value", "options", "isMulti", "placeholder", "isExclusive", "disabled"],
  data: ()=>({
    componentValue: null,
    open: false,
  }),
  computed: {
    shownValue() {
      var result=[];
      if (this.isMulti && this.value && this.value.length) {
        for(var val of this.value) {
          var item=(this.options || []).find(a=>a.value==val);
          if (item)
            result.push(item.label);
        }
      } else if (!this.isMulti && this.value) {
        var item=(this.options || []).find(a=>a.value==this.value);
          if (item)
            result.push(item.label);
      }
      return result.join(", ") || this.placeholder || "";
    },
  },
  watch: {
    componentValue: {
      handler() {
        var newValue=this.componentValue;
        if (this.isMulti && this.isExclusive && this.componentValue && this.componentValue.length) {
          var found=false;
          for(var val of this.componentValue.slice().reverse()) {
            var item=(this.options || []).find(a=>a.value==val);
            if (item) {
              if (found) {
//                 console.log(val, newValue, newValue.indexOf(val));
                newValue.splice(newValue.indexOf(val), 1);
              } else {
                found=true;
              }
            }
          }
        }
        this.$emit("input", newValue);
      }, deep: true,
    },
    value: {
      handler() {
        if (this.isMulti)
          this.componentValue=this.value || [];
        else
          this.componentValue=this.value || "";
      }, deep: true, immediate: true,
    },
  },
  methods: {
    doSelect() {
      if (!this.isMulti || this.isExclusive) {
        setTimeout(()=>{
          this.open=false;
        }, 50);
      }
    },
  },
  mounted() {
    $(document).click((e)=>{
      if (this.$refs.ddlist)
      if ($(e.target).closest(".multi-dropdown")[0]==this.$el)
        return;
      this.open=false;
    });
  },
}
</script>
