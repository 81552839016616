<template>
<div class="wysi-editor" ref="editor" style="" v-html="actualValue"></div>
</template>
<script>
export default {
  props: ["value", "placeholder", "editable"],
  data: ()=>({
    actualValue: "",
    actualValue2: "",
  }),
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (this.actualValue2!=this.value && this.editor) {
          this.editor.setContent(this.value);
        }
      },
      immediate: true,
    },
    editable: {
      handler() {
        if (!this.editor)
          return;
        if (this.editable)
          this.editor.mode.set("design");
        else
          this.editor.mode.set("readonly");
      },
      immediate: true,
    },
  },
  mounted() {
    this.actualValue=this.value;
    this.actualValue2=this.value;
    var config={
      target: this.$refs.editor,
      plugins: [ 'lists', 'link', 'paste', 'table'/*, 'placeholder'*/],
      link_title: false,
      menubar: false,
      contextmenu: false,
//       inline: true,
      statusbar: false,
      height: 400,
      valid_styles: {
        "*": "color, text-decoration"
      },
      toolbar: "bold italic underline strikethrough forecolor | alignleft aligncenter alignright alignjustify | link unlink | numlist bullist outdent indent",
//       placeholder: this.placeholder,
      formats: {
        serif: {block: "p", classes: "serif", exact: true},
      },
      style_formats: [
        {title: "Serif", format: "serif"},
      ],
      init_instance_callback: (editor)=>{
        this.initEditor(editor);
      },
      setup: (editor)=>{
        this.setupEditor(editor);
        
//         editor.ui.registry.addToggleButton('serifText', {
//           icon: 'change-case',
//           tooltip: "Toggle serif font",
//           onAction: function (_) {
//             editor.execCommand('mceToggleFormat', false, 'serif');
//           },
//           onSetup: function (api) {
//             editor.formatter.formatChanged('serif', function (state) {
//               api.setActive(state);
//             });
//           }
//         });
      },
    };
    tinymce.init(config);
  },
  methods: {
    initEditor(editor) {
      this.editor=editor;
      editor.setContent(this.value || "");
      editor.on('change', (e)=>{
        var content=editor.getContent();
        this.actualValue2=content;
        this.$emit("input", content);
      });
      if (this.editable)
        this.editor.mode.set("design");
      else
        this.editor.mode.set("readonly");
    },
    setupEditor(editor) {
      editor.on('keydown', (e)=>{
      });
      editor.on('paste', (e)=>{
      });
    }
  }
}
</script>
